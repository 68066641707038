import React, { Component, Suspense} from 'react';
import { Switch, Route, BrowserRouter  } from 'react-router-dom';
import Presentation from './Presentation';
//import JeuQuiz from '../../jeux/quiz/JeuQuiz';


const JeuPourcentage = React.lazy(() => import('../../jeux/pourcentage/JeuPourcentage'));
const JeuSuite = React.lazy(() => import('../../jeux/suite/JeuSuite'));
const TestSuite = React.lazy(() => import('../../jeux/testSuite/TestSuite'));
const JeuOrdre = React.lazy(() => import('../../jeux/ordre/JeuOrdre'));
export default class Routeur extends Component {

    render() {
        return (
          



    <BrowserRouter >  
    <Switch >
    <Suspense fallback={<div>Chargement de la page...</div>}>
    <Route exact path='/' component={Presentation} />
        <Route path='/menu' component={Presentation} />
        <Route path={'/juste-nombre/:categorie'} render={(props) => <JeuPourcentage {...props} keyProp={'jeuPourcentage'} key={Math.floor(Math.random() * 10000)} />} />            
        <Route path={'/suite'} render={(props) => <JeuSuite {...props} keyProp={'jeuSuite'} key={Math.floor(Math.random() * 10000)} />}  />          
            <Route path={'/ordre/:no'} render={(props) => <JeuOrdre {...props} keyProp={'jeuOrdre'} key={Math.floor(Math.random() * 10000)} />}   />         
            <Route path={'/tt'} render={(props) => <TestSuite {...props} keyProp={'tt'} key={Math.floor(Math.random() * 10000)} />}   />        

        </Suspense>          
   </Switch>  
   </BrowserRouter>
);


    }
}